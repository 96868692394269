import { Grid, makeStyles } from "@material-ui/core"
import { useContext, useEffect, useState } from "react"

import { useSnackbar } from "notistack"
import { useParams } from "react-router-dom/cjs/react-router-dom"
import LoadingContext from "../../contexts/loadingContext"
import {
    cadastrarCategoria,
    cadastrarGrupoDeOpcao,
    cadastrarImagem,
    cadastrarOpcao,
    cadastrarProduto,
    duplicarCategoria,
    duplicarGrupoDeOpcao,
    duplicarProduto,
    excluirCategoria,
    excluirGrupoDeOpcao,
    excluirOpcao,
    excluirProduto,
    obterCardapioParaEdicaoEmMassa,
    salvarCardapioCompleto,
} from "../../services/cardapio"
import { ObterCardapioNovo } from "../../services/clientefiel"
import Cabecalho from "../Cabecalho"
import { CheckboxProvider } from "./componentes/CheckboxContext"
import EdicaoEmMassa from "./componentes/EdicaoEmMassa"
import NovaTelaProdutos from "./componentes/NovaTelaProdutos"

const useStyles = makeStyles(() => ({
    container: {
        display: "flex",
        justifyContent: "center",
        marginTop: "5.5em",
        "@media (max-width: 400px)": {
            marginTop: "12em",
        },
    },
}))

export default function CardapioNovo({ estabelecimento }) {
    const Authorization = localStorage.getItem("chaveSistema")

    const autorizacao = localStorage.getItem("chaveSistema")
    const classes = useStyles()
    const { setLoading } = useContext(LoadingContext)

    const { enqueueSnackbar } = useSnackbar()
    const alertStart = (msg, tipo) => {
        enqueueSnackbar(msg, { variant: tipo })
    }

    const { tela } = useParams()

    const [cadastrar, setCadastrar] = useState(true)
    const [cardapio, setCardapio] = useState()
    const [categorias, setCategorias] = useState()
    const [items, setItems] = useState()

    useEffect(() => {
        const buscarCardapioProduto = async () => {
            setLoading(true)
            const response = await ObterCardapioNovo(autorizacao, {
                idEstabelecimento: estabelecimento?.id,
                token: "a~I?;;+_@!AL^",
            })

            if (response.retornoErro) {
                alertStart(response.mensagem, "error")
            } else {
                setCardapio(response)
                setCategorias(response.categorias)
            }
            setLoading(false)
        }
        const buscarCardapioMassa = async () => {
            setLoading(true)
            const response = await obterCardapioParaEdicaoEmMassa(estabelecimento?.id, autorizacao)
            if (response.retornoErro) {
                alertStart(response.mensagem, "error")
                return false
            } else {
                setItems(response)
            }
            setLoading(false)
        }
        if (tela === "produtos") {
            buscarCardapioProduto()
        } else {
            buscarCardapioMassa()
        }
    }, [tela])

    const handleSalvarCategoria = async data => {
        setLoading(true)
        const response = await cadastrarCategoria(cardapio?.id, data)

        if (response.retornoErro) {
            console.error(response)
            alertStart(response.mensagem, "error")
        } else {
            if (cadastrar) {
                setCategorias(prev => {
                    categorias.push(response)
                    return [...prev]
                })
                alertStart("Categoria cadastrada com sucesso", "success")
            } else {
                const categoria = categorias.findIndex(c => c.id === data.id)
                setCategorias(prev => {
                    prev[categoria] = response
                    return [...prev]
                })
                alertStart("Categoria editada com sucesso", "success")
            }
            console.warn("CadastrarCategoria => ", response)
        }
        setLoading(false)
    }
    let mudarCategoria = false

    const handleSalvarProduto = async (idCategoria, data) => {
        setLoading(true)

        const categoriaProduto = categorias.findIndex(c => c.id === idCategoria)

        if (categorias[categoriaProduto]?.produtos.findIndex(c => c.id === data.id) === -1) {
            mudarCategoria = true
        }

        const response = await cadastrarProduto(idCategoria, data)

        if (response.retornoErro) {
            setLoading(false)
            console.error(response)
            alertStart(response.mensagem, "error")
            return false
        } else {
            if (cadastrar) {
                if (categoriaProduto === -1) return categorias

                setCategorias(prev => {
                    prev[categoriaProduto]?.produtos?.push(response)
                    return [...prev]
                })
                alertStart("Produto cadastrado com sucesso", "success")
            } else if (mudarCategoria) {
                const categoriasAtualizadas = categorias?.map(categoria => {
                    if (categoria?.produtos?.some(produto => produto?.id === data?.id)) {
                        return {
                            ...categoria,
                            produtos: categoria?.produtos?.filter(
                                produto => produto?.id !== data?.id
                            ),
                        }
                    } else if (categoria?.id === idCategoria) {
                        return {
                            ...categoria,
                            produtos: [...categoria?.produtos, response],
                        }
                    }
                    return categoria
                })
                setCategorias(categoriasAtualizadas)
                alertStart("Produto editado com sucesso", "success")
            } else {
                setCategorias(prev => {
                    const indiceProduto = prev[categoriaProduto].produtos?.findIndex(
                        p => p.id === data.id
                    )
                    if (indiceProduto === -1) return prev
                    prev[categoriaProduto].produtos[indiceProduto] = response
                    return [...prev]
                })
                alertStart("Produto editado com sucesso", "success")
            }

            console.warn("CadastrarProduto => ", response)
        }

        mudarCategoria = false
        setLoading(false)
        return true
    }

    const handleCadastrarGrupoDeOpcao = async (idProduto, data) => {
        setLoading(true)
        const categoriaIndex = categorias.findIndex(categoria =>
            categoria.produtos.some(produto => produto.id === idProduto)
        )
        if (categoriaIndex === -1) {
            console.error("Categoria não encontrada.")
            return
        }

        const produtoIndex = categorias[categoriaIndex].produtos.findIndex(
            produto => produto.id === idProduto
        )
        if (produtoIndex === -1) {
            console.error("Produto não encontrado.")
            return
        }

        const response = await cadastrarGrupoDeOpcao(idProduto, data)

        if (response.retornoErro) {
            setLoading(false)
            alertStart(response.mensagem, "error")
            console.error(response)
        } else {
            if (cadastrar) {
                setCategorias(prev => {
                    prev[categoriaIndex]?.produtos[produtoIndex]?.opcoes.push(response)
                    return [...prev]
                })
                alertStart("Passo de montagem cadastrado com sucesso", "success")
            } else {
                setCategorias(prev => {
                    const opcaoIndex = prev[categoriaIndex]?.produtos[
                        produtoIndex
                    ]?.opcoes.findIndex(o => o.id === data.id)
                    if (opcaoIndex === -1) return prev
                    prev[categoriaIndex].produtos[produtoIndex].opcoes[opcaoIndex] = response
                    return [...prev]
                })
                alertStart("Passo de montagem editado com sucesso", "success")
            }

            console.warn("CadastrarGrupoDeOpcao => ", response)
        }
        setLoading(false)
    }

    const handleCadastrarOpcao = async (idProduto, idPasso) => {
        setLoading(true)

        const categoriaIndex = categorias.findIndex(categoria =>
            categoria.produtos.some(produto => produto.id === idProduto)
        )
        if (categoriaIndex === -1) {
            console.error("Categoria não encontrada.")
            return
        }

        const produtoIndex = categorias[categoriaIndex].produtos.findIndex(
            produto => produto.id === idProduto
        )
        if (produtoIndex === -1) {
            console.error("Produto não encontrado.")
            return
        }

        const passoIndex = categorias[categoriaIndex].produtos[produtoIndex].opcoes.findIndex(
            passo => passo.id === idPasso
        )
        if (passoIndex === -1) {
            console.error("Produto não encontrado.")
            return
        }

        const data = {
            nome: "",
            valor: "",
            codigo: "",
            descricao: "",
            ativo: true,
            ordemExib: 0,
        }

        const response = await cadastrarOpcao(idPasso, data)

        if (response.retornoErro) {
            console.error(response)
            alertStart(response.mensagem, "error")
        } else {
            console.warn("cadastrarOpcao => ", response)
            setCategorias(prev => {
                const updatedCategorias = [...prev]
                updatedCategorias[categoriaIndex].produtos[produtoIndex].opcoes[
                    passoIndex
                ].opcoes.push(response)
                return updatedCategorias
            })
            alertStart("Opção cadastrada com sucesso", "success")
        }

        setLoading(false)
    }

    const handleDuplicarCategoria = async idCategoria => {
        setLoading(true)
        const data = { id: idCategoria }

        const response = await duplicarCategoria(data)

        if (response.retornoErro) {
            setLoading(false)
            alertStart(response.mensagem, "error")
            console.error(response)
        } else {
            setCategorias(prev => {
                categorias.push(response)
                return [...prev]
            })
            console.warn("DuplicarCategoria => ", response)
            alertStart("Categoria duplicada com sucesso", "success")
        }
        setLoading(false)
    }

    const handleDuplicarProduto = async (idProduto, idCategoria) => {
        setLoading(true)
        const categoriaProduto = categorias.findIndex(c => c.id === idCategoria)

        if (categorias[categoriaProduto]?.produtos?.findIndex(c => c.id === idProduto) === -1) {
            mudarCategoria = true
        }

        const data = { id: idProduto }

        const response = await duplicarProduto(data)
        if (response.retornoErro) {
            setLoading(false)
            alertStart(response.mensagem, "error")
            console.error(response)
        } else {
            if (categoriaProduto === -1) return categorias
            delete response.md5Imagem
            delete response.impressaoEmSeparado
            delete response.tipo
            delete response.idCategoria
            delete response.categoriaTemp
            setCategorias(prev => {
                prev[categoriaProduto]?.produtos?.push(response)
                return [...prev]
            })
            console.warn("DuplicarProduto => ", response)
            alertStart("Produto duplicado com sucesso", "success")
        }
        setLoading(false)
    }

    const handleDuplicarPassoDeMontagem = async (idProduto, idPasso) => {
        setLoading(true)
        const categoriaIndex = categorias.findIndex(categoria =>
            categoria.produtos.some(produto => produto.id === idProduto)
        )
        if (categoriaIndex === -1) {
            console.error("Categoria não encontrada.")
            return
        }

        const produtoIndex = categorias[categoriaIndex].produtos.findIndex(
            produto => produto.id === idProduto
        )
        if (produtoIndex === -1) {
            console.error("Produto não encontrado.")
            return
        }

        const data = { id: idPasso }

        const response = await duplicarGrupoDeOpcao(data, idProduto)
        if (response.retornoErro) {
            setLoading(false)
            alertStart(response.mensagem, "error")
            console.error(response)
        } else {
            setCategorias(prev => {
                const updatedCategorias = [...prev]
                updatedCategorias[categoriaIndex].produtos[produtoIndex].opcoes.push(response)
                return updatedCategorias
            })
            console.warn("DuplicarGrupoDeOpcao => ", response)
            alertStart("Passo de montagem duplicado com sucesso", "success")
        }
        setLoading(false)
    }

    const handleExcluirCategoria = async idCategoria => {
        setLoading(true)
        const data = { id: idCategoria }

        const response = await excluirCategoria(data)

        if (response.retornoErro) {
            setLoading(false)
            alertStart(response.mensagem, "error")
            console.error(response)
        } else {
            setCategorias(prev => {
                return prev.filter(item => item.id !== idCategoria)
            })
            console.warn("ExcluirCategoria => ", response)
            alertStart("Categoria excluída com sucesso", "success")
        }
        setLoading(false)
    }

    const handleExcluirProduto = async (idProduto, idCategoria) => {
        setLoading(true)
        const categoriaProduto = categorias.findIndex(c => c.id === idCategoria)

        if (categoriaProduto === -1) {
            console.warn("Produto não encontrado ")
            return
        }
        const produtoIndex = categorias[categoriaProduto]?.produtos?.findIndex(
            p => p.id === idProduto
        )
        if (produtoIndex === -1) {
            console.warn("Produto não encontrado ")
            return
        }

        const data = { id: idProduto }
        const response = await excluirProduto(data)

        if (response.retornoErro) {
            setLoading(false)
            alertStart(response.mensagem, "error")
            console.error(response)
        } else {
            setCategorias(prev => {
                const updatedCategorias = [...prev]
                updatedCategorias[categoriaProduto].produtos = updatedCategorias[
                    categoriaProduto
                ].produtos.filter(p => p.id !== idProduto)
                return updatedCategorias
            })
            console.warn("ExcluirProduto => ", response)
            alertStart("Produto excluído com sucesso", "success")
        }
        setLoading(false)
    }

    const handleExcluirGrupoDeOpcao = async (idProduto, idGrupoOpcao) => {
        setLoading(true)
        const categoriaIndex = categorias.findIndex(categoria =>
            categoria.produtos.some(produto => produto.id === idProduto)
        )
        if (categoriaIndex === -1) {
            console.error("Categoria não encontrada.")
            return
        }

        const produtoIndex = categorias[categoriaIndex].produtos.findIndex(
            produto => produto.id === idProduto
        )
        if (produtoIndex === -1) {
            console.error("Produto não encontrado.")
            return
        }

        const data = { id: idGrupoOpcao }

        const response = await excluirGrupoDeOpcao(data)

        if (response.retornoErro) {
            setLoading(false)
            alertStart(response.mensagem, "error")
            console.error(response)
        } else {
            setCategorias(prev => {
                const updatedCategorias = [...prev]
                updatedCategorias[categoriaIndex].produtos[produtoIndex].opcoes = updatedCategorias[
                    categoriaIndex
                ].produtos[produtoIndex].opcoes.filter(o => o.id !== idGrupoOpcao)
                return updatedCategorias
            })
            console.warn("ExcluirGrupoDeOpcao => ", response)
            alertStart("Passo de montagem excluído com sucesso", "success")
        }
        setLoading(false)
    }

    const handleExcluirOpcao = async (idProduto, idPasso, idOpcao, index) => {
        const categoriaIndex = categorias.findIndex(categoria =>
            categoria.produtos.some(produto => produto.id === idProduto)
        )
        if (categoriaIndex === -1) {
            console.error("Categoria não encontrada.")
            return
        }

        const produtoIndex = categorias[categoriaIndex].produtos.findIndex(
            produto => produto.id === idProduto
        )
        if (produtoIndex === -1) {
            console.error("Produto não encontrado.")
            return
        }

        const passoIndex = categorias[categoriaIndex].produtos[produtoIndex].opcoes.findIndex(
            passo => passo.id === idPasso
        )
        if (passoIndex === -1) {
            console.error("Passo não encontrado.")
            return
        }

        if (!idOpcao) {
            setCategorias(prev => {
                const updatedCategorias = [...prev]
                updatedCategorias[categoriaIndex].produtos[produtoIndex].opcoes[
                    passoIndex
                ].opcoes.splice(index, 1)
                return updatedCategorias
            })
            return
        }

        setLoading(true)

        const data = { id: idOpcao }

        const response = await excluirOpcao(data)

        if (response.retornoErro) {
            setLoading(false)
            alertStart(response.mensagem, "error")
            console.error(response)
        } else {
            setCategorias(prev => {
                const updatedCategorias = [...prev]
                updatedCategorias[categoriaIndex].produtos[produtoIndex].opcoes[passoIndex].opcoes =
                    updatedCategorias[categoriaIndex].produtos[produtoIndex].opcoes[
                        passoIndex
                    ].opcoes.filter(o => o.id !== idOpcao)
                return updatedCategorias
            })
            console.warn("ExcluirOpcao => ", response)
            alertStart("Opção de montagem excluída com sucesso", "success")
        }
        setLoading(false)
    }

    const idsSet = new Set()
    const handleIdsEditar = idProduto => {
        idsSet.add(idProduto)
    }
    const [salvarCardapio, setSalvarCardapioCompleto] = useState(false)

    const handleSalvarCardapioInteiro = async () => {
        const ids = Array.from(idsSet)

        if (ids.length >= 1 || salvarCardapio) {
            setLoading(true)
            let data = null

            if (salvarCardapio) {
                data = cardapio
            } else {
                data = {
                    ...cardapio,
                    categorias: categorias
                        .map(categoria => {
                            const produtosFiltrados = categoria.produtos.filter(produto =>
                                ids.includes(produto.id)
                            )

                            if (produtosFiltrados.length > 0) {
                                return {
                                    ...categoria,
                                    produtos: produtosFiltrados,
                                }
                            }
                            return null
                        })
                        .filter(categoria => categoria !== null),
                }
            }

            const response = await salvarCardapioCompleto(estabelecimento.id, data, Authorization)
            if (response.retornoErro) {
                console.error(response)
                alertStart(response.mensagem, "error")
            } else {
                console.warn("salvarCardapioCompleto => ", response)
                alertStart("Cardapio salvo com sucesso", "success")
            }

            idsSet.clear()
            setSalvarCardapioCompleto(false)
            setLoading(false)
        } else {
            return
        }
    }

    const handleCadastrarImagem = async (
        nomeArquivo,
        idProduto,
        idPasso,
        idOpcao,
        file,
        edicaoMassa
    ) => {
        if (idProduto === null && idPasso === null && idOpcao === null) {
            setLoading(true)
            try {
                const response = await cadastrarImagem(
                    estabelecimento.id,
                    nomeArquivo,
                    idProduto,
                    idOpcao,
                    file,
                    Authorization
                )
                if (response.retornoErro) {
                    console.error(response)
                    alertStart(response.mensagem, "error")
                } else {
                    return response.valor
                }
            } catch (error) {
                console.error(error)
            } finally {
                setLoading(false)
            }
        }

        const categoriaIndex =
            typeof idProduto === "string"
                ? categorias.findIndex(categoria =>
                      categoria.produtos.some(produto => produto.nome === idProduto)
                  )
                : categorias.findIndex(categoria =>
                      categoria.produtos.some(produto => produto.id === idProduto)
                  )
        if (categoriaIndex === -1) {
            console.error("Categoria não encontrada.")
            return
        }

        const produtoIndex =
            typeof idProduto === "string"
                ? categorias[categoriaIndex].produtos.findIndex(
                      produto => produto.nome === idProduto
                  )
                : categorias[categoriaIndex].produtos.findIndex(produto => produto.id === idProduto)
        if (produtoIndex === -1) {
            console.error("Produto não encontrado.")
            return
        }

        const passoIndex = idPasso
            ? categorias[categoriaIndex].produtos[produtoIndex].opcoes.findIndex(
                  passo => passo.id === idPasso
              )
            : null
        if (passoIndex === -1) {
            console.error("Passo não encontrado.")
            return
        }

        const opcaoIndex = idOpcao
            ? categorias[categoriaIndex]?.produtos[produtoIndex]?.opcoes[
                  passoIndex
              ]?.opcoes.findIndex(opcao => opcao.id === idOpcao)
            : null
        if (opcaoIndex === -1) {
            console.error("Passo não encontrado.")
            return
        }

        try {
            setLoading(true)
            const response = await cadastrarImagem(
                estabelecimento.id,
                nomeArquivo,
                idProduto,
                idOpcao,
                file,
                Authorization
            )
            if (response.retornoErro) {
                console.error(response)
                alertStart(response.mensagem, "error")
            } else {
                if (idOpcao) {
                    if (edicaoMassa) {
                        return response.valor
                    } else {
                        setCategorias(prev => {
                            prev[categoriaIndex].produtos[produtoIndex].opcoes[passoIndex].opcoes[
                                opcaoIndex
                            ].urlImagem = response.valor
                            return [...prev]
                        })
                    }
                } else {
                    if (edicaoMassa) {
                        return response.valor
                    } else {
                        setCategorias(prev => {
                            prev[categoriaIndex].produtos[produtoIndex].urlImagem = response.valor
                            return [...prev]
                        })
                    }
                }
                console.warn("salvarCardapioCompleto => ", response)
                alertStart("Imagem atualizada com sucesso", "success")
            }
        } catch (error) {
            console.error(error)
        } finally {
            setLoading(false)
        }
    }

    return (
        <Grid>
            <Cabecalho pagina={"Edição de Cardápio"} telasEdicao />
            <div className={classes.container}>
                {tela === "produtos" ? (
                    <NovaTelaProdutos
                        cardapio={cardapio}
                        estabelecimento={estabelecimento}
                        cadastrar={cadastrar}
                        setCadastrar={setCadastrar}
                        categorias={categorias}
                        setCategorias={setCategorias}
                        handleSalvarCategoria={handleSalvarCategoria}
                        handleSalvarProduto={handleSalvarProduto}
                        handleDuplicarCategoria={handleDuplicarCategoria}
                        handleDuplicarProduto={handleDuplicarProduto}
                        handleDuplicarPassoDeMontagem={handleDuplicarPassoDeMontagem}
                        handleExcluirCategoria={handleExcluirCategoria}
                        handleExcluirProduto={handleExcluirProduto}
                        handleCadastrarGrupoDeOpcao={handleCadastrarGrupoDeOpcao}
                        handleExcluirGrupoDeOpcao={handleExcluirGrupoDeOpcao}
                        handleCadastrarOpcao={handleCadastrarOpcao}
                        handleExcluirOpcao={handleExcluirOpcao}
                        handleSalvarCardapioInteiro={handleSalvarCardapioInteiro}
                        handleIdsEditar={handleIdsEditar}
                        idsSet={idsSet}
                        handleCadastrarImagem={handleCadastrarImagem}
                        setSalvarCardapioCompleto={setSalvarCardapioCompleto}
                    />
                ) : tela === "massa" ? (
                    <CheckboxProvider>
                        <EdicaoEmMassa
                            items={items}
                            setItems={setItems}
                            estabelecimento={estabelecimento}
                            handleCadastrarImagem={handleCadastrarImagem}
                        />
                    </CheckboxProvider>
                ) : null}
            </div>
        </Grid>
    )
}
