import api from "./api"
import { RegistrarLogErro } from "./clientefiel"

function tratamentoResponse(response, chamada) {
    if (response.data.codErro > 0) {
        RegistrarLogErro(
            response.data.mensagem,
            chamada ?? "tratamentoResponse",
            response.config?.data
        )
        return { retornoErro: response.data.codErro, mensagem: response.data.mensagem }
    } else if (typeof response.data == "string" && response.data !== "") {
        RegistrarLogErro(response.data, chamada ?? "tratamentoResponse", response.config?.data) //, traceId)
        return {
            retornoErro: 1,
            mensagem: chamada + " - Erro inesperado, entre em contato com os reponsáveis.",
        }
    } else return response.data
}

const ErroRequisicao = function (data, aplicativoDados) {
    try {
        console.log("ErroRequisicao> ", data.request, data.message)
        if (data.message == "Network Error") {
            return "Verifique sua conexão!"
            // return 'Sem Internet ou sistema fora do ar!'
        } else if (data.message) {
            return "Erro: " + data.message
        }
        return false
    } catch (error) {
        RegistrarLogErro(error, "ErroRequisicao", data)
    }
}

export const AtualizarStatusPedido = async function (data, aplicativoDados) {
    try {
        const response = await api
            .post("cliente/AtualizarStatusPedido", data, {
                headers: { "Content-Type": "application/json" },
            })
            .catch(function (error) {
                const resp = ErroRequisicao(error)
                return { data: { codErro: 1, mensagem: resp } }
            })

        console.log("AtualizarStatusPedido => ", response)

        return tratamentoResponse(response, "AtualizarStatusPedido")
    } catch (error) {
        RegistrarLogErro(error, "AtualizarStatusPedido", data)
        return { retornoErro: 1, mensagem: error.message }
    }
}

export const CadastrarPedido = async function (data, aplicativoDados) {
    try {
        const response = await api
            .post("cliente/CadastrarPedido", data, {
                headers: { "Content-Type": "application/json" },
            })
            .catch(function (error) {
                const resp = ErroRequisicao(error)
                return { data: { codErro: 1, mensagem: resp } }
            })

        console.log("CadastrarPedido => ", response)

        return tratamentoResponse(response, "CadastrarPedido")
    } catch (error) {
        RegistrarLogErro(error, "CadastrarPedido", data)
        return { retornoErro: 1, mensagem: error.message }
    }
}

export const listarEnderecos = async function (Authorization, data, appName) {
    try {
        const response = await api.post("cliente/ObterEnderecosComTaxaEntrega", data, {
            headers: {
                "Content-Type": "application/json",
                Authorization: Authorization,
                app: appName,
            },
        })

        console.log("listarEnderecos => ", response)

        return tratamentoResponse(response, "listarEnderecos")
    } catch (error) {
        RegistrarLogErro(error, "listarEnderecos", data)
        return { retornoErro: 1, mensagem: error.message }
    }
}

export const CadastrarEnderecoCliente = async function (Authorization, data) {
    try {
        const response = await api
            .post("cliente/CadastrarEnderecoCliente", data, {
                headers: { "Content-Type": "application/json", Authorization: Authorization },
            })
            .catch(function (error) {
                const resp = ErroRequisicao(error)
                return { data: { codErro: 1, mensagem: resp } }
            })

        console.log("CadastrarEnderecoCliente => ", response)

        return tratamentoResponse(response, "CadastrarEnderecoCliente")
    } catch (error) {
        RegistrarLogErro(error, "CadastrarEnderecoCliente", data)
        return { retornoErro: 1, mensagem: error.message }
    }
}

export const AtualizarEnderecoCliente = async function (Authorization, data) {
    try {
        const response = await api.post("cliente/CadastrarEnderecoCliente", data, {
            headers: { "Content-Type": "application/json", Authorization: Authorization },
        })

        console.log("AtualizarEnderecoCliente => ", response)

        if (response.data.codErro > 0)
            return { retornoErro: response.data.codErro, mensagem: response.data.mensagem }
        else if (typeof response.data == "string")
            return {
                retornoErro: 1,
                mensagem: "Erro inesperado, entre em contato com os reponsáveis.",
            }
        else return response.data
    } catch (error) {
        RegistrarLogErro(error, "AtualizarEnderecoCliente", data)
        return { retornoErro: 1, mensagem: error.message }
    }
}

export const CadastrarUsuarioBasico = async function (Authorization, data) {
    try {
        const response = await api.post("cliente/CadastrarUsuarioBasico", data, {
            headers: { "Content-Type": "application/json", Authorization: Authorization },
        })

        console.log("CadastrarUsuarioBasico => ", response)

        if (response.data.codErro > 0)
            return { retornoErro: response.data.codErro, mensagem: response.data.mensagem }
        else if (typeof response.data == "string")
            return {
                retornoErro: 1,
                mensagem: "Erro inesperado, entre em contato com os reponsáveis.",
            }
        else return response.data
    } catch (error) {
        RegistrarLogErro(error, "CadastrarUsuarioBasico", data)
        return { retornoErro: 1, mensagem: error.message }
    }
}

export const CadastrarUsuario = async function (Authorization, data) {
    try {
        const response = await api
            .post("cliente/CadastrarUsuario", data, {
                headers: { "Content-Type": "application/json", Authorization: Authorization },
            })
            .catch(function (error) {
                const resp = ErroRequisicao(error)
                return { data: { codErro: 1, mensagem: resp } }
            })

        console.log("CadastrarUsuario => ", response)

        return tratamentoResponse(response, "CadastrarUsuario")
    } catch (error) {
        RegistrarLogErro(error, "CadastrarUsuario", data)
        return { retornoErro: 1, mensagem: error.message }
    }
}
