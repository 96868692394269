import { useContext, useEffect, useState } from "react"
import ServicosContext from "../../contexts/servicosContext"

import { useHistory } from "react-router-dom"
import flatErro from "../../assets/fail-flat.png"
import flatLoading from "../../assets/loading-flat.gif"
import api from "../../services/api"

//alert
import Snackbar from "@material-ui/core/Snackbar"
import MuiAlert from "@material-ui/lab/Alert"
import { verificarConfigsUsuario } from "../../services/functionsGerais"

export default function Filter(props) {
    const history = useHistory()
    //let qtdTest = 3;

    const { buscarParametros } = useContext(ServicosContext)

    const search = window.location.search
    const params = new URLSearchParams(search)

    const paramsChave = params.get("chave")
    const paramsTela = params.get("tela")

    const [mensagemErro, setMensagemErro] = useState("")
    const [naoEncontrou, setNaoEncontrou] = useState(false)

    const jaCarregado = !!localStorage.getItem("aplicativoCF")
    console.log("jaCarregado", jaCarregado)

    const [alert, setAlert] = useState({ status: false, tipo: "success", mesangem: "" })

    function Alert(props) {
        return <MuiAlert elevation={6} variant="filled" {...props} />
    }

    const alertStart = (msg, tipo) => {
        setAlert({ status: true, tipo: tipo, mesangem: msg })
    }

    const alertClose = (event, reason) => {
        if (reason === "clickaway") {
            return
        }
        setAlert({ status: false, tipo: alert.tipo, mesangem: alert.mesangem })
    }

    const irParaTela = (paramsTela, parametros = {}) => {
        switch (paramsTela) {
            case "recebedor":
                if (true) {
                    //verificador de permissão
                    console.log("recebedor")
                    history.push("/servicos/modulo/recebedor-de-pedidos")
                } else {
                    history.push("/servicos")
                }
                break
            case "registrador":
                if (parametros?.exibirRegistroPedidos) {
                    //verificador de permissão
                    console.log("registrador")
                    history.push("/servicos/modulo/registrador-de-pedidos")
                } else {
                    history.push("/servicos")
                }
                break
            case "pedidomesa":
                if (parametros?.possuiModuloPedidosMesa && parametros?.urlSiteAplicativo) {
                    //verificador de permissão
                    console.log("pedidomesa")
                    history.push("/servicos/modulo/gerenciador-de-mesas")
                } else {
                    history.push("/servicos")
                }
                break
            case "cardapio":
                history.push("/servicos/ferramenta/edicao-cardapio/produtos")
                // history.push("/servicos/ferramenta/edicao-cardapio/produtos")
                window.location.pathname = "/servicos/ferramenta/edicao-cardapio/produtos"

            default:
                console.log("servicos")
                history.push("/servicos")
                break
        }
    }

    async function verificarAppDados() {
        // console.log('api', api.options())
        try {
            if (navigator.onLine) {
                var aplicativoDados = {}

                if (jaCarregado === false) {
                    var urlAppDados = window.location.hostname.includes("localhost")
                        ? "painel.clientefiel.app"
                        : window.location.hostname
                    // if(!localStorage.getItem('siteUrlTeste')){
                    //   urlAppDados = localStorage.getItem('siteUrlTeste');
                    // }

                    // urlAppDados = 'painel.pinn.delivery'
                    const data = {
                        urlAcesso: urlAppDados,
                        token: "a~I?;;+_@!AL^",
                    }
                    const response = await api.post("clientefiel/AplicativoDadosUrl", data, {
                        headers: {
                            "Content-Type": "application/json",
                        },
                    })

                    console.log(
                        "buscanod aplicativo",
                        typeof response.data,
                        "retorno",
                        response.data
                    )

                    if (!(response.data && typeof response.data !== "string")) {
                        console.log("filter 1")
                        setMensagemErro(response.data)
                        setNaoEncontrou(true)
                        alertStart("Erro ao procurar aplicativo", "error")
                    } else if (response.data.codErro > 0) {
                        console.log("filter 2", response.data)
                        setNaoEncontrou(true)
                        setMensagemErro(response.data.mensagem)
                        alertStart(response.data.mensagem, "error")
                    } else {
                        aplicativoDados = response.data
                    }
                } else {
                    aplicativoDados = JSON.parse(localStorage.getItem("aplicativoCF"))
                }

                if (aplicativoDados.id) {
                    localStorage.setItem("aplicativoCF", JSON.stringify(aplicativoDados))
                    localStorage.setItem("dateAplicativoDadosCF", new Date())

                    if (paramsChave) {
                        await api
                            .post(
                                `fornecedor/LoginFornecedorAutomatico`,
                                {},
                                {
                                    headers: {
                                        "Content-Type": "application/json",
                                        authorization: paramsChave,
                                    },
                                }
                            )
                            .then(async response => {
                                console.log("LoginFornecedor", response)
                                if (typeof response.data == "string" || response.data.codErro > 0) {
                                    history.push("/login" + search)
                                } else {
                                    localStorage.setItem("chaveSistema", paramsChave)

                                    verificarConfigsUsuario(response.data)
                                    const newConfigs = await buscarParametros(paramsChave)

                                    if (paramsTela) {
                                        irParaTela(paramsTela, newConfigs.user.parametros)
                                    } else {
                                        history.push("/servicos")
                                    }
                                }
                            })
                            .catch(() => {
                                history.push("/login" + search)
                            })
                    } else {
                        history.push("/login" + search)
                    }
                } else {
                    window.setTimeout(function () {
                        window.location.href = window.location.href
                    }, 10000)
                }
            } else {
                history.push("/offline")
            }
        } catch (err) {
            setMensagemErro(err.message)
            setNaoEncontrou(true)
            alertStart("Erro nao identificado ao buscar aplicativo", "error")
        }
    }

    useEffect(() => {
        if (naoEncontrou === false) {
            verificarAppDados()
        }
    }, [naoEncontrou, verificarAppDados])

    return (
        <div className="divImageCentro">
            <Snackbar open={alert.status} autoHideDuration={6000} onClose={alertClose}>
                <Alert onClose={alertClose} severity={alert.tipo}>
                    {alert.mesangem}
                </Alert>
            </Snackbar>

            {naoEncontrou === false ? (
                <>
                    <img
                        src={flatLoading}
                        className="pretoEBranco"
                        alt={"carregando"}
                        style={{ width: "350px" }}
                    />
                    <h6 style={{ fontWeight: "100" }}>Carregando App...</h6>{" "}
                </>
            ) : (
                <>
                    <img
                        src={flatErro}
                        className="pretoEBranco"
                        alt={"falha ao carregar"}
                        style={{ width: "350px" }}
                    />
                    <h6 style={{ fontWeight: "100" }}>Não Encontramos Esse Aplicativo...</h6>{" "}
                    {mensagemErro}
                </>
            )}
        </div>
    )
}
