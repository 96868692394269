import {
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    FormControl,
    Grid,
    InputLabel,
    makeStyles,
    MenuItem,
    Select,
    TextField,
    Typography,
} from "@material-ui/core"
import { AddAPhoto } from "@material-ui/icons"
import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab"
import { useEffect, useRef, useState } from "react"

const useStyles = makeStyles(theme => ({
    titulo: {
        backgroundColor: "white",
    },
    container: {
        backgroundColor: "white",
        overflowY: "hidden",
    },
    containerImg: {
        margin: "auto",
        width: "200px",
        height: "200px",
        backgroundColor: "#eee",
        border: "2px dashed #ccc",
        cursor: "pointer",
    },
    addAPhoto: {
        width: "30%",
        height: "30%",
        color: "rgba(0,0,0,0.5)",
    },
    textoImg: {
        color: "rgba(0,0,0,0.5)",
    },
    input: {
        display: "none",
    },
    botoes: {
        backgroundColor: "white",
    },
    botaoExcluir: {
        backgroundColor: "red",
        color: "white",
        "&:hover": {
            backgroundColor: "#FF4D4D",
        },
    },
    botaoSalvar: {
        backgroundColor: "#14A44D",
        color: "white",
        "&:hover": {
            backgroundColor: "#28B76C",
        },
    },
    toggleButton: {
        backgroundColor: "rgba(0,0,0,0.1)",
        color: "rgba(255,0,0,0.5)",

        "&.Mui-selected": {
            backgroundColor: "#28a745",
            color: "white",
        },

        "&:hover": {
            backgroundColor: "#3CB371",
        },
    },
}))

export default function DialogProdutos({
    open,
    modalProduto,
    cadastrar,
    handleCloseProduto,
    handleSalvarProduto,
    categorias,
    categoriaAtual,
    handleCadastrarImagem,
    estabelecimento,
}) {
    function encontrarCategoriaIdPorProduto() {
        const categoria = categorias?.find(categoria =>
            categoria?.produtos?.some(produto => produto?.id === modalProduto?.id)
        )
        return categoria ? categoria?.id : null
    }

    const fileInputRef = useRef(null)
    const handleDivClick = () => {
        fileInputRef.current.click()
    }
    const [imagem, setImagem] = useState("")

    const handleFileChange = async event => {
        const file = event.target.files[0]
        if (file) {
            const nomeArquivo = file.name
            const idProduto = null
            const idOpcao = null
            const idPasso = null

            const response = await handleCadastrarImagem(
                nomeArquivo,
                idProduto,
                idPasso,
                idOpcao,
                file
            )

            setImagem(response)
        }
    }

    const [tela, setTela] = useState("Geral")

    const id = modalProduto?.id
    const versao = modalProduto?.versao
    const nomeRef = useRef(modalProduto?.nome)
    const valorRef = useRef(modalProduto?.valor)
    const codigoRef = useRef(modalProduto?.codigo)
    const ordemExibRef = useRef(modalProduto?.ordemExib ? modalProduto?.ordemExib : 0)

    const [idCategoria, setIdCategoria] = useState()
    const handleChangeIdCategoria = e => setIdCategoria(e.target.value)
    const [disponivelDomingo, setDisponivelDomingo] = useState("")
    const handleChangeDisponivelDomingo = () => setDisponivelDomingo(!disponivelDomingo)
    const [disponivelSegunda, setDisponivelSegunda] = useState("")
    const handleChangeDisponivelSegunda = () => setDisponivelSegunda(!disponivelSegunda)
    const [disponivelTerca, setDisponivelTerca] = useState("")
    const handleChangeDisponivelTerca = () => setDisponivelTerca(!disponivelTerca)
    const [disponivelQuarta, setDisponivelQuarta] = useState("")
    const handleChangeDisponivelQuarta = () => setDisponivelQuarta(!disponivelQuarta)
    const [disponivelQuinta, setDisponivelQuinta] = useState("")
    const handleChangeDisponivelQuinta = () => setDisponivelQuinta(!disponivelQuinta)
    const [disponivelSexta, setDisponivelSexta] = useState("")
    const handleChangeDisponivelSexta = () => setDisponivelSexta(!disponivelSexta)
    const [disponivelSabado, setDisponivelSabado] = useState("")
    const handleChangeDisponivelSabado = () => setDisponivelSabado(!disponivelSabado)
    const descricaoRef = useRef(modalProduto?.descricao)

    const ativoRef = useRef(modalProduto?.ativo)
    const permiteObservacaoRef = useRef(modalProduto?.permiteObservacao)

    const valorDomingoRef = useRef(modalProduto?.disponibilidadeProduto?.valorDomingo)
    const valorSegundaRef = useRef(modalProduto?.disponibilidadeProduto?.valorSegunda)
    const valorTercaRef = useRef(modalProduto?.disponibilidadeProduto?.valorTerca)
    const valorQuartaRef = useRef(modalProduto?.disponibilidadeProduto?.valorQuarta)
    const valorQuintaRef = useRef(modalProduto?.disponibilidadeProduto?.valorQuinta)
    const valorSextaRef = useRef(modalProduto?.disponibilidadeProduto?.valorSexta)
    const valorSabadoRef = useRef(modalProduto?.disponibilidadeProduto?.valorSabado)

    const apenasPrimeiraCompraRef = useRef(modalProduto?.apenasPrimeiraCompra)
    const produtoExibicaoRef = useRef(modalProduto?.produtoExibicao)
    const promocionalRef = useRef(modalProduto?.promocional)
    const pontuarProgramaFidelidadeRef = useRef(modalProduto?.pontuarProgramaFidelidade)

    useEffect(() => {
        if (!cadastrar) {
            setImagem(modalProduto?.urlImagem)
            setIdCategoria(encontrarCategoriaIdPorProduto())
            setDisponivelDomingo(modalProduto?.disponibilidadeProduto?.disponivelDomingo)
            setDisponivelSegunda(modalProduto?.disponibilidadeProduto?.disponivelSegunda)
            setDisponivelTerca(modalProduto?.disponibilidadeProduto?.disponivelTerca)
            setDisponivelQuarta(modalProduto?.disponibilidadeProduto?.disponivelQuarta)
            setDisponivelQuinta(modalProduto?.disponibilidadeProduto?.disponivelQuinta)
            setDisponivelSexta(modalProduto?.disponibilidadeProduto?.disponivelSexta)
            setDisponivelSabado(modalProduto?.disponibilidadeProduto?.disponivelSabado)
        } else {
            setImagem("")
            setIdCategoria(categoriaAtual.id)
            setDisponivelDomingo(true)
            setDisponivelSegunda(true)
            setDisponivelTerca(true)
            setDisponivelQuarta(true)
            setDisponivelQuinta(true)
            setDisponivelSexta(true)
            setDisponivelSabado(true)
        }
    }, [modalProduto, categoriaAtual])

    const classes = useStyles()

    const handleChange = e => {
        valorRef.current.value = e.target.value.replace(".", ",")
    }

    return (
        <>
            {open && (
                <Dialog open={open} onClose={handleCloseProduto} maxWidth="md">
                    <DialogTitle id="alert-dialog-title" className={classes.titulo}>
                        {cadastrar ? "Cadastrar Produto" : "Editar Produto"}
                    </DialogTitle>
                    <form
                        onSubmit={e => {
                            e.preventDefault()
                            const data = {
                                token: "a~I?;;+_@!AL^",
                                id,
                                versao,

                                urlImagem: imagem,
                                nome: nomeRef?.current?.value,
                                valor: parseFloat(valorRef?.current?.value.replace(",", ".")),
                                codigo: codigoRef?.current?.value,
                                ordemExib: ordemExibRef?.current?.value,
                                descricao: descricaoRef?.current?.value,
                                ativo: ativoRef?.current?.checked,
                                permiteObservacao: permiteObservacaoRef?.current?.checked,

                                disponibilidadeProduto: {
                                    disponivelDomingo,
                                    disponivelSegunda,
                                    disponivelTerca,
                                    disponivelQuarta,
                                    disponivelQuinta,
                                    disponivelSexta,
                                    disponivelSabado,
                                    valorDomingo: valorDomingoRef?.current?.value,
                                    valorSegunda: valorSegundaRef?.current?.value,
                                    valorTerca: valorTercaRef?.current?.value,
                                    valorQuarta: valorQuartaRef?.current?.value,
                                    valorQuinta: valorQuintaRef?.current?.value,
                                    valorSexta: valorSextaRef?.current?.value,
                                    valorSabado: valorSabadoRef?.current?.value,
                                },

                                apenasPrimeiraCompra: apenasPrimeiraCompraRef?.current?.checked,
                                produtoExibicao: produtoExibicaoRef?.current?.checked,
                                promocional: promocionalRef?.current?.checked,
                                pontuarProgramaFidelidade:
                                    pontuarProgramaFidelidadeRef?.current?.checked,
                            }
                            handleSalvarProduto(idCategoria, data)
                            handleCloseProduto()
                        }}
                    >
                        <DialogContent
                            className={classes.container}
                            style={{ overflowY: "hidden" }}
                        >
                            <Grid>
                                <Button
                                    onClick={() => {
                                        setTela("Geral")
                                    }}
                                >
                                    Geral
                                </Button>
                                <Button
                                    onClick={() => {
                                        setTela("ValorPromocional")
                                    }}
                                >
                                    Valores Promocionais
                                </Button>
                                <Button
                                    onClick={() => {
                                        setTela("Avançado")
                                    }}
                                >
                                    Avançado
                                </Button>
                                {/* <Button
                            // onClick={() => {
                            //     setTela("Fiscal")
                            // }}
                            >
                                Fiscal
                            </Button> */}
                                <Divider />
                            </Grid>
                            <div style={{ display: tela === "Geral" ? "" : "none" }}>
                                <Grid container direction="row" spacing={2}>
                                    <Grid
                                        className={classes.containerImg}
                                        item
                                        container
                                        justifyContent="center"
                                        alignItems="center"
                                        onClick={handleDivClick}
                                    >
                                        {!imagem ? (
                                            <Grid
                                                container
                                                direction="column"
                                                justifyContent="center"
                                                alignItems="center"
                                            >
                                                <AddAPhoto className={classes.addAPhoto} />
                                                <Typography className={classes.textoImg}>
                                                    Selecione uma imagem
                                                </Typography>
                                            </Grid>
                                        ) : (
                                            <img src={imagem} height="180px" />
                                        )}
                                        <input
                                            type="file"
                                            ref={fileInputRef}
                                            onChange={handleFileChange}
                                            className={classes.input}
                                            accept="image/*"
                                        />
                                    </Grid>

                                    <Grid item xs={12} sm={12} md={12} container spacing={1}>
                                        <Grid item xs={12} sm={4} md={4}>
                                            <TextField
                                                fullWidth
                                                type="text"
                                                variant="outlined"
                                                label="Nome"
                                                placeholder="Ex: X-Tudo"
                                                InputLabelProps={{ shrink: true }}
                                                inputRef={nomeRef}
                                                defaultValue={
                                                    nomeRef?.current?.value ?? modalProduto?.nome
                                                }
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={4} md={4}>
                                            <TextField
                                                fullWidth
                                                // type="number"
                                                variant="outlined"
                                                label="Preço"
                                                placeholder="R$ 00,00"
                                                InputLabelProps={{ shrink: true }}
                                                inputRef={valorRef}
                                                defaultValue={modalProduto?.valor}
                                                onChange={handleChange}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={4} md={4}>
                                            <TextField
                                                fullWidth
                                                type="number"
                                                variant="outlined"
                                                label="Código PDV"
                                                placeholder="Código PDV"
                                                InputLabelProps={{ shrink: true }}
                                                inputRef={codigoRef}
                                                defaultValue={modalProduto?.codigo}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={4}>
                                            <TextField
                                                fullWidth
                                                type="number"
                                                variant="outlined"
                                                label="Posição"
                                                placeholder="999"
                                                InputLabelProps={{ shrink: true }}
                                                inputRef={ordemExibRef}
                                                defaultValue={modalProduto?.ordemExib ?? 0}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={4}>
                                            <FormControl fullWidth variant="outlined">
                                                <InputLabel>Categoria</InputLabel>
                                                <Select
                                                    label="Categoria"
                                                    defaultValue={idCategoria}
                                                    onChange={handleChangeIdCategoria}
                                                >
                                                    {categorias.map(categoria => (
                                                        <MenuItem value={categoria.id}>
                                                            {categoria.nome}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                        <Grid
                                            item
                                            xs={12}
                                            sm={12}
                                            md={4}
                                            container
                                            justifyContent="center"
                                        >
                                            <ToggleButtonGroup fullWidth>
                                                <ToggleButton
                                                    defaultValue={modalProduto?.disponivelDomingo}
                                                    selected={disponivelDomingo}
                                                    onClick={handleChangeDisponivelDomingo}
                                                    className={classes.toggleButton}
                                                >
                                                    D
                                                </ToggleButton>
                                                <ToggleButton
                                                    defaultValue={modalProduto?.disponivelSegunda}
                                                    selected={disponivelSegunda}
                                                    onClick={handleChangeDisponivelSegunda}
                                                    className={classes.toggleButton}
                                                >
                                                    S
                                                </ToggleButton>
                                                <ToggleButton
                                                    defaultValue={modalProduto?.disponivelTerca}
                                                    selected={disponivelTerca}
                                                    onClick={handleChangeDisponivelTerca}
                                                    className={classes.toggleButton}
                                                >
                                                    T
                                                </ToggleButton>
                                                <ToggleButton
                                                    value={modalProduto?.disponivelQuarta}
                                                    selected={disponivelQuarta}
                                                    onClick={handleChangeDisponivelQuarta}
                                                    className={classes.toggleButton}
                                                >
                                                    Q
                                                </ToggleButton>
                                                <ToggleButton
                                                    defaultValue={modalProduto?.disponivelQuinta}
                                                    selected={disponivelQuinta}
                                                    onClick={handleChangeDisponivelQuinta}
                                                    className={classes.toggleButton}
                                                >
                                                    Q
                                                </ToggleButton>
                                                <ToggleButton
                                                    defaultValue={modalProduto?.disponivelSexta}
                                                    selected={disponivelSexta}
                                                    onClick={handleChangeDisponivelSexta}
                                                    className={classes.toggleButton}
                                                >
                                                    S
                                                </ToggleButton>
                                                <ToggleButton
                                                    defaultValue={modalProduto?.disponivelSabado}
                                                    selected={disponivelSabado}
                                                    onClick={handleChangeDisponivelSabado}
                                                    className={classes.toggleButton}
                                                >
                                                    S
                                                </ToggleButton>
                                            </ToggleButtonGroup>
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={12}>
                                            <TextField
                                                multiline
                                                minRows={3}
                                                fullWidth
                                                variant="outlined"
                                                label="Descrição"
                                                placeholder="Ex: pão macio, hambúrguer suculento, fatias de queijo derretido, presunto, bacon crocante, ovo frito com a gema molinha, alface crocante, tomate fresco, milho verde, ervilha, batata palha, maionese, ketchup e mostarda."
                                                InputLabelProps={{ shrink: true }}
                                                inputRef={descricaoRef}
                                                defaultValue={modalProduto?.descricao}
                                            />
                                        </Grid>
                                        <Grid
                                            item
                                            xs={12}
                                            sm={12}
                                            md={6}
                                            container
                                            alignItems="center"
                                        >
                                            <Checkbox
                                                inputRef={ativoRef}
                                                defaultChecked={
                                                    cadastrar ? true : modalProduto.ativo
                                                }
                                            />
                                            <Typography>Disponível</Typography>
                                        </Grid>
                                        <Grid
                                            item
                                            xs={12}
                                            sm={12}
                                            md={6}
                                            container
                                            alignItems="center"
                                        >
                                            <Checkbox
                                                inputRef={permiteObservacaoRef}
                                                defaultChecked={
                                                    cadastrar
                                                        ? true
                                                        : modalProduto.permiteObservacao
                                                }
                                            />
                                            <Typography>Permite Observação</Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </div>
                            <div style={{ display: tela === "ValorPromocional" ? "" : "none" }}>
                                <Grid container direction="row" spacing={2}>
                                    <Grid item xs={12} sm={4} md={4}>
                                        <TextField
                                            fullWidth
                                            type="number"
                                            variant="outlined"
                                            label="Domingo"
                                            placeholder="R$ 00,00"
                                            InputLabelProps={{ shrink: true }}
                                            inputRef={valorDomingoRef}
                                            defaultValue={
                                                modalProduto?.disponibilidadeProduto?.valorDomingo
                                            }
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={4} md={4}>
                                        <TextField
                                            fullWidth
                                            type="number"
                                            variant="outlined"
                                            label="Segunda"
                                            placeholder="R$ 00,00"
                                            InputLabelProps={{ shrink: true }}
                                            inputRef={valorSegundaRef}
                                            defaultValue={
                                                modalProduto?.disponibilidadeProduto?.valorSegunda
                                            }
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={4} md={4}>
                                        <TextField
                                            fullWidth
                                            type="number"
                                            variant="outlined"
                                            label="Terça"
                                            placeholder="R$ 00,00"
                                            InputLabelProps={{ shrink: true }}
                                            inputRef={valorTercaRef}
                                            defaultValue={
                                                modalProduto?.disponibilidadeProduto?.valorTerca
                                            }
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={4} md={4}>
                                        <TextField
                                            fullWidth
                                            type="number"
                                            variant="outlined"
                                            label="Quarta"
                                            placeholder="R$ 00,00"
                                            InputLabelProps={{ shrink: true }}
                                            inputRef={valorQuartaRef}
                                            defaultValue={
                                                modalProduto?.disponibilidadeProduto?.valorQuarta
                                            }
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={4} md={4}>
                                        <TextField
                                            fullWidth
                                            type="number"
                                            variant="outlined"
                                            label="Quinta"
                                            placeholder="R$ 00,00"
                                            InputLabelProps={{ shrink: true }}
                                            inputRef={valorQuintaRef}
                                            defaultValue={
                                                modalProduto?.disponibilidadeProduto?.valorQuinta
                                            }
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={4} md={4}>
                                        <TextField
                                            fullWidth
                                            type="number"
                                            variant="outlined"
                                            label="Sexta"
                                            placeholder="R$ 00,00"
                                            InputLabelProps={{ shrink: true }}
                                            inputRef={valorSextaRef}
                                            defaultValue={
                                                modalProduto?.disponibilidadeProduto?.valorSexta
                                            }
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={4} md={4}>
                                        <TextField
                                            fullWidth
                                            type="number"
                                            variant="outlined"
                                            label="Sabado"
                                            placeholder="R$ 00,00"
                                            InputLabelProps={{ shrink: true }}
                                            inputRef={valorSabadoRef}
                                            defaultValue={
                                                modalProduto?.disponibilidadeProduto?.valorSabado
                                            }
                                        />
                                    </Grid>
                                </Grid>
                            </div>
                            <div style={{ display: tela === "Avançado" ? "" : "none" }}>
                                <Grid container xs={12} sm={12} md={12}>
                                    <Grid item xs={12} sm={12} md={6} container alignItems="center">
                                        <Checkbox
                                            inputRef={apenasPrimeiraCompraRef}
                                            defaultChecked={modalProduto?.apenasPrimeiraCompra}
                                        />
                                        <Typography>Apenas Primeira Compra</Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={6} container alignItems="center">
                                        <Checkbox
                                            inputRef={produtoExibicaoRef}
                                            defaultChecked={modalProduto?.produtoExibicao}
                                        />
                                        <Typography>Produto apenas informativo</Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={6} container alignItems="center">
                                        <Checkbox
                                            inputRef={promocionalRef}
                                            defaultChecked={modalProduto?.promocional}
                                        />
                                        <Typography> Bloquear descontos percentuais</Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={6} container alignItems="center">
                                        <Checkbox
                                            inputRef={pontuarProgramaFidelidadeRef}
                                            defaultChecked={
                                                cadastrar
                                                    ? true
                                                    : modalProduto?.pontuarProgramaFidelidade
                                            }
                                        />
                                        <Typography>Pontua Fidelidade</Typography>
                                    </Grid>
                                </Grid>
                            </div>
                        </DialogContent>
                        <DialogActions className={classes.botoes}>
                            <Button onClick={handleCloseProduto} className={classes.botaoExcluir}>
                                Voltar
                            </Button>
                            <Button type="submit" className={classes.botaoSalvar}>
                                Salvar
                            </Button>
                        </DialogActions>
                    </form>
                </Dialog>
            )}
        </>
    )
}
