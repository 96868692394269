import {
    Button,
    Card,
    CardActionArea,
    CardActions,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    Grid,
    IconButton,
    InputLabel,
    makeStyles,
    MenuItem,
    Select,
    Slide,
    Typography,
} from "@material-ui/core"
import React, { useContext, useEffect, useState } from "react"

import Cabecalho from "../Cabecalho"

import { Settings } from "@material-ui/icons"
import { useSnackbar } from "notistack"
import LoadingContext from "../../contexts/loadingContext"
import useParametros from "../../contexts/ParametrosContext"
import ServicosContext from "../../contexts/servicosContext"
import { RegistrarLogErro } from "../../services/clientefiel"
import { BuscarMesas } from "../../services/integracao"
import { valorTotalDaMesa } from "../RegistradorDePedidos/functions"
import DetalhesPedido from "./DetalhesPedido"
import ListaPedidosMesa from "./ListaPedidosMesa"

const useStyles = makeStyles(theme => ({
    containerCabecalho: {
        marginTop: "4.3em",
    },
    containerCartoesMesa: {
        display: "grid",
        gridTemplateColumns: "repeat(auto-fit, minmax(140px, 1fr))",
        gap: "0.5rem",
        padding: "1rem",
        paddingTop: "0",
    },
    cartaoMesa: { height: "8rem", display: "flex", flexDirection: "column" },
    cartaoMesaDados: {
        flex: 5,
        textAlign: "center",
        alignItems: "center",
        padding: "0.5rem 1rem",
        gap: "1rem",
    },
    cartaoPedidoMesa: {
        height: "9rem",
        display: "flex",
        flexDirection: "column",
        marginRight: "0",
    },
    numeroMesa: {
        flex: 2,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    dadosMesa: {
        flex: 2,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
    },
    nomeMesa: {
        width: "40%",
        display: "flex",
        flexDirection: "column",
        "& > span": {
            display: "inline-flex",
            whiteSpace: "nowrap",
            overflow: "hidden",
            margin: "0 auto",
            width: "100%",
            justifyContent: "center",
        },
    },
    statusMesa: {
        padding: "2px 0",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        gap: "0.25rem",
        "& .numero-mesa-aberta": {
            display: "inline-flex",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "white",
            color: "#28a745",
            height: "1.5em",
            aspectRatio: "1 / 1",
            borderRadius: "100%",
        },
    },
    dadosPedidoMesa_container: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-around",
        alignItems: "center",
        flex: 4,
    },
    cartaoMesaDesconto: {
        padding: 0,
        flex: 2,
    },
    cartaoMesaDesconto_texto: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        color: "white",
        width: "100%",
        height: "100%",
    },
    valorDesconto: {
        background: "#28a745",
    },
    semDesconto: {
        background: "#6c757d",
    },
    noPadding: { padding: 0 },
    botaoEstaticoAdd: {
        background: "#28a745",
        color: "white",
        position: "fixed",
        bottom: "1.75rem",
        right: "1.75rem",
        "& > span": {
            display: "flex",
            gap: "0.25rem",
        },
        "&:hover": {
            backgroundColor: "#2fc550",
        },
    },
    botaoEstaticoClose: {
        background: "#dc3545",
        color: "white",
        position: "fixed",
        bottom: "8.75rem",
        right: "1.75rem",
        "& > span": {
            display: "flex",
            gap: "0.25rem",
        },
        "&:hover": {
            backgroundColor: "#EB4B5B",
        },
        "@media (max-width: 500px)": {
            bottom: "5.25rem",
        },
    },
    botaoEstaticoImpressao: {
        background: "#007bff",
        color: "white",
        position: "fixed",
        bottom: "5.25rem",
        right: "1.75rem",
        "& > span": {
            display: "flex",
            gap: "0.25rem",
        },
        "&:hover": {
            backgroundColor: "#66b3ff",
        },
        "@media (max-width: 500px)": {
            display: "none",
        },
    },
    appBar: {
        "& > div": {
            justifyContent: "space-between",
        },
    },
    opcaoImpressoraLabel: {
        background: "white",
        borderRadius: "10px",
        padding: "2px 10px",
    },
    selecaoImpressora: {
        width: "200px",
        background: "white",
        opacity: 0.85,
    },
    botoesImpressoras: {
        backgroundColor: "gray",
        borderRadius: "10px",
        width: "100%",
        color: "white",
        "&:hover": {
            backgroundColor: "#A9A9A9",
        },
        [theme.breakpoints.down("xs")]: {
            flexDirection: "column",
        },
        "@media (max-width: 500px)": {
            display: "none",
        },
    },
    listaPedidos: {
        display: "flex",
        flexDirection: "column",
        gap: "1em",
        textAlign: "center",
    },
    botaoImprimir: {
        color: "#28a745",
    },
    botaoFecharMesa: { color: "white", background: "#dc3545" },
    barraControle: {
        height: "3.5em",
        display: "flex",
        alignItems: "center",
        justifyContent: "right",
        paddingInline: "1em",
    },
    nomeDaMesa: {
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
        overflow: "hidden",
    },
    numeroDaMesa: {
        minWidth: "8rem",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        width: "100%",
        "& > div": {
            display: "flex",
            alignItems: "center",
        },
    },
    valorFortmatado: {
        borderRadius: "10px",
        padding: "0em 0.4em",
        backgroundColor: "gray",
    },
    containerDePagamento: {
        display: "flex",
        gap: "0.5rem",
        justifyContent: "stretch",
    },
    cardItens: {
        display: "flex",
        gap: "1em",
        flexDirection: "column",
        marginBottom: "1em",
        height: "80%",
        backgroundColor: "#f4f4f4",
        overflow: "auto",
        padding: "0.5em",
        borderRadius: "10px",
        width: "100%",
    },
    paperCardPedido: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        padding: "5px 10px",
    },
    actionsConfiguracoes: {
        "& > button": {
            color: "white",
            "&:first-child": {
                backgroundColor: "#dc3545",
                "&:hover": {
                    backgroundColor: "#f46a6a",
                },
                "@media (max-width: 500px)": {
                    width: "10em",
                },
            },
            "&:last-child": {
                backgroundColor: "#28a745",
                "&:hover": {
                    backgroundColor: "#34d058",
                },
                "@media (max-width: 500px)": {
                    width: "10em",
                },
            },
        },
    },
    actionsCardPedidos: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",

        "& > button": {
            color: "white",
            "@media (max-width: 500px)": {
                display: "none",
            },
            backgroundColor: "#007bff",
            "&:hover": {
                backgroundColor: "#66b3ff",
            },
        },
        "& > p": {
            cursor: "pointer",
            color: "#28a745",
        },
    },
    parametrosDeImpressao: {
        "@media (max-width: 500px)": {
            display: "none",
        },
    },
    containerCardPedido: {
        marginBottom: "10em",
        padding: "1",
        maxWidth: "100%",
    },
    cardPedido: {
        display: "flex",
        flexDirection: "column",
        height: "32em",
        width: "100%",
        backgroundColor: "white",
        padding: "1em",
        borderRadius: "1em",
        "@media (max-width: 500px)": {
            height: "auto",
            maxHeight: "32em",
        },
    },
    tituloPedido: {
        display: "flex",
        justifyContent: "space-between",
        padding: "0.5em 1em",
        width: "100%",
    },
}))

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />
})

const CardMesa = props => {
    const { configuracoes } = useContext(ServicosContext)
    const classes = useStyles()
    let aberta = false
    if (Object.keys(props.mesa).length !== 1) {
        aberta = true
    }
    const totalMesa = valorTotalDaMesa(props?.mesa ? props?.mesa?.pedidosMesa : null)

    const nomeMesa =
        props?.mesa?.pedidosMesa?.length > 0 ? props?.mesa?.pedidosMesa[0].cliente?.nome : "Anônimo"

    const handleClick = () => {
        let mesa = {}
        props.mesa ? (mesa = props.mesa) : (mesa.numeroMesa = props.index)
        props.setMesaSelecionada(props.index)
    }

    return (
        <Card className={classes.cartaoMesa}>
            <CardActionArea onClick={handleClick} className={classes.cartaoMesaDados}>
                <Typography variant="h3" component="span" className={classes.numeroMesa}>
                    {aberta ? null : props.index}
                </Typography>
                {aberta ? (
                    <>
                        <div>
                            <Typography className={classes.nomeDaMesa}>{nomeMesa}</Typography>
                        </div>
                        <span style={{ margin: "auto" }}>
                            <Typography variant="body2" color="textSecondary" component="p">
                                {props.mesa.pedidosMesa?.length
                                    ? props.mesa.pedidosMesa?.length
                                    : 0}{" "}
                                pedidos
                            </Typography>
                            <Typography variant="body1" component="span">
                                {Intl.NumberFormat("pt-BR", {
                                    style: "currency",
                                    currency: "BRL",
                                }).format(totalMesa)}
                            </Typography>
                        </span>
                    </>
                ) : null}
            </CardActionArea>
            <CardActions className={classes.noPadding}>
                {aberta ? (
                    <Typography
                        variant="button"
                        className={[classes.statusMesa, "botaoVerde naoArredondado"].join(" ")}
                    >
                        ABERTA <span className="numero-mesa-aberta">{props.index}</span>
                    </Typography>
                ) : (
                    <Typography
                        variant="button"
                        className={[classes.statusMesa, "botaoCinza naoArredondado"].join(" ")}
                    >
                        FECHADA
                    </Typography>
                )}
            </CardActions>
        </Card>
    )
}

const GerenciadorMesas = () => {
    const autorizacao = localStorage.getItem("chaveSistema")

    const [listaMesas, setListaMesas] = useState([])
    const [mesaSelecionada, setMesaSelecionada] = useState()
    const [pedidoSelecionado, setPedidoSelecionado] = useState({})
    const [closeDetalhes, setCloseDetalhes] = useState(false)

    const [impressorasQZ, setImpressorasQZ] = useState([])

    const classes = useStyles()

    const { setLoading } = useContext(LoadingContext)

    // const lista = listaMesas.filter(e => !e.naoTem)

    const { enqueueSnackbar } = useSnackbar()
    const alertStart = (msg, tipo) => {
        enqueueSnackbar(msg, { variant: tipo })
    }

    const atualizarMesas = async () => {
        setLoading(true)
        try {
            const retorno = await BuscarMesas(autorizacao)
            if (retorno.retornoErro) {
                alertStart(retorno.mensagem, "error")
                return false
            }

            if (retorno.length === 0) {
                setListaMesas([{ naoTem: true }])
            } else {
                setListaMesas(retorno)
            }
        } catch (error) {
            RegistrarLogErro(error, "handleOpenGerenciamentoMesa")
            alertStart("Procure os desenvolvedores! Erro: " + error.message, "error")
        }
        setLoading(false)
    }

    useEffect(() => {
        atualizarMesas()
    }, [])

    const mesasAbertas = listaMesas
        .sort((a, b) => a.numeroMesa - b.numeroMesa)
        .map(mesa => mesa.numeroMesa)

    const qtdMesaLocal = JSON.parse(localStorage.getItem("qtdMesas"))

    const [qtdMesas, setQtdMesas] = useState(qtdMesaLocal ?? 51)
    const handleChangeqtdMesas = e => {
        setQtdMesas(e.target.value)
        localStorage.setItem("qtdMesas", e.target.value)
    }

    const parametros = useParametros()

    useEffect(() => {
        if (parametros?.PUBLIC_NUMERO_MESAS < 50) {
            setQtdMesas(Number(parametros?.PUBLIC_NUMERO_MESAS) + 1)
        }
    }, [parametros])

    const todasMesas = new Array(qtdMesas).fill().map((_, index) => ({ numeroMesa: index }))
    todasMesas.forEach((elemento, index, array) => {
        if (mesasAbertas[index] >= 1 && mesasAbertas[index] < todasMesas.length) {
            array[mesasAbertas[index]] = listaMesas[index]
        }
    })

    const [open, setOpen] = useState(false)
    const handleClickOpen = () => {
        setOpen(true)
    }
    const handleClose = () => {
        setOpen(false)
    }

    const mesasRenderizadas = [11, 21, 31, 41, 51]
    return (
        <div>
            <Dialog fullWidth={true} open={open} onClose={handleClose}>
                <DialogTitle>Configurações</DialogTitle>
                <DialogContent>
                    <Grid container>
                        <Grid item container xs={12} sm={6} md={6}>
                            <FormControl variant="outlined" fullWidth>
                                <InputLabel id="demo-simple-select-label">
                                    Quantidade de mesas
                                </InputLabel>
                                <Select
                                    value={qtdMesas}
                                    label="Quantidade de mesas"
                                    onChange={handleChangeqtdMesas}
                                >
                                    {Number(parametros?.PUBLIC_NUMERO_MESAS) < 50 ? (
                                        <MenuItem value={qtdMesas}>Todas</MenuItem>
                                    ) : (
                                        mesasRenderizadas.map((e, index) => (
                                            <MenuItem key={index} value={e}>
                                                {e - 1} Mesas
                                            </MenuItem>
                                        ))
                                    )}
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Fechar</Button>
                </DialogActions>
            </Dialog>
            <div className={classes.containerCabecalho}>
                <Cabecalho pagina="Gerenciador de Mesas" gerarQRCode />
            </div>
            <Grid container justifyContent="flex-end">
                <IconButton onClick={handleClickOpen}>
                    <Settings />
                </IconButton>
            </Grid>
            <div className={classes.containerCartoesMesa}>
                {todasMesas.map((mesa, index) =>
                    index >= 1 ? (
                        <CardMesa
                            key={index}
                            index={index}
                            mesa={mesa}
                            setMesaSelecionada={setMesaSelecionada}
                        />
                    ) : null
                )}
            </div>
            <ListaPedidosMesa
                mesa={mesaSelecionada && todasMesas[mesaSelecionada]}
                setPedidoSelecionado={setPedidoSelecionado}
                handleClose={() => setMesaSelecionada(undefined)}
                useStyles={useStyles}
                Transition={Transition}
                impressorasQZ={impressorasQZ}
                setImpressorasQZ={setImpressorasQZ}
                alertStart={alertStart}
                todasMesas={todasMesas}
                atualizarMesas={atualizarMesas}
            />
            <DetalhesPedido
                pedidoSelecionado={pedidoSelecionado}
                handleClose={() => {
                    setCloseDetalhes(true)
                    setTimeout(() => {
                        setPedidoSelecionado({})
                        setCloseDetalhes(false)
                    }, 200)
                }}
                Transition={Transition}
                close={closeDetalhes}
                useStyles={useStyles}
            />
        </div>
    )
}

export default GerenciadorMesas
