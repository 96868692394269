import {
    Checkbox,
    Grid,
    InputAdornment,
    ListItem,
    makeStyles,
    TextField,
    Typography,
} from "@material-ui/core"
import { AddAPhoto } from "@material-ui/icons"
import { useSnackbar } from "notistack"
import { useContext, useRef } from "react"
import LoadingContext from "../../../contexts/loadingContext"
import { inativarOpcao, inativarProduto } from "../../../services/cardapio"
import { useCheckboxContext } from "./CheckboxContext"
import DisponibilidadeSwitch from "./DisponibilidadeSwitch"

const useStyles = makeStyles(() => ({
    containerImg: {
        margin: "auto",
        maxWidth: "100px",
        height: "100px",
        backgroundColor: "#eee",
        border: "2px dashed #ccc",
        cursor: "pointer",
        overflow: "hidden",
        borderRadius: "10px",
    },
    addAPhoto: {
        width: "40%",
        height: "40%",
        color: "rgba(0,0,0,0.5)",
    },
    textoImg: {
        color: "rgba(0,0,0,0.5)",
    },
    input: {
        display: "none",
    },
    textos: {
        fontWeight: "bold",
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        width: "100%",
    },
}))

export default function ItemMassa({
    index,
    produto,
    handleInputChange,
    handleCadastrarImagem,
    setItems,
}) {
    const { setLoading } = useContext(LoadingContext)
    const { enqueueSnackbar } = useSnackbar()

    const alertStart = (msg, tipo) => {
        enqueueSnackbar(msg, { variant: tipo })
    }

    const item = produto

    const fileInputRef = useRef(null)
    const handleDivClick = () => {
        fileInputRef.current.click()
    }

    const handleFileChange = async event => {
        const file = event.target.files[0]

        if (file) {
            const nomeArquivo = file.name
            const idProduto = item.tipo === "P" ? produto.id : item.produto
            const idOpcao = item.tipo === "O" ? produto.id : null
            const idPasso = null
            const edicaoMassa = true

            const response = await handleCadastrarImagem(
                nomeArquivo,
                idProduto,
                idPasso,
                idOpcao,
                file,
                edicaoMassa
            )

            setItems(prev => {
                const newItems = [...prev]
                if (item.tipo === "P") {
                    newItems[index].urlImagemProduto = response
                } else {
                    newItems[index].urlImagemOpcao = response
                }
                return newItems
            })
        }
    }

    const classes = useStyles()

    const { checkedItems, toggleItem } = useCheckboxContext()
    const checked = checkedItems[item.id] || false

    const handleChangeCodigoPDV = e => {
        item.codigoPDV = e.target.value
    }

    const handleChangeDisponibilidadeProduto = async () => {
        setLoading(true)
        const response = await inativarProduto(item?.id, item?.ativo)
        if (response.retornoErro) {
            console.error(response)
            alertStart(response.mensagem, "error")
        } else {
            item.ativo = !item.ativo
            alertStart("Disponibilidade do produto alterada com sucesso", "success")
        }
        setLoading(false)
    }

    const handleChangeDisponibilidadeOpcao = async () => {
        setLoading(true)
        const response = await inativarOpcao(item?.id, item?.ativo)
        if (response.retornoErro) {
            console.error(response)
            alertStart(response.mensagem, "error")
        } else {
            item.ativo = !item.ativo
            alertStart("Disponibilidade da opção alterada com sucesso", "success")
        }
        setLoading(false)
    }

    return (
        <ListItem key={item?.id} divider>
            <Grid>
                <Checkbox color="primary" onChange={() => toggleItem(item?.id)} checked={checked} />
            </Grid>
            <Grid container alignItems="center" justifyContent="space-between">
                <Grid xs={12} sm={12} md={4} item container alignItems="center" spacing={1}>
                    <Grid
                        className={classes.containerImg}
                        item
                        container
                        justifyContent="center"
                        alignItems="center"
                        onClick={handleDivClick}
                    >
                        <Grid
                            container
                            direction="column"
                            justifyContent="center"
                            alignItems="center"
                        >
                            {!item.urlImagemProduto && !item.urlImagemOpcao ? (
                                <Grid
                                    container
                                    direction="column"
                                    justifyContent="center"
                                    alignItems="center"
                                >
                                    <AddAPhoto className={classes.addAPhoto} />
                                </Grid>
                            ) : (
                                <img
                                    style={{
                                        borderRadius: "10px",
                                        width: "100%",
                                        height: "100%",
                                        objectFit: "cover",
                                        objectPosition: "center",
                                    }}
                                    src={
                                        item.tipo === "P"
                                            ? item.urlImagemProduto
                                            : item.urlImagemOpcao
                                    }
                                    alt="Uploaded"
                                />
                            )}
                        </Grid>
                        <input
                            type="file"
                            ref={fileInputRef}
                            onChange={handleFileChange}
                            className={classes.input}
                            accept="image/*"
                        />
                    </Grid>
                    <Grid item container xs={12} sm={6} md={8} direction="column">
                        <Typography>{item.tipo === "P" ? "Produto" : "Adicional"}</Typography>
                        <Typography className={classes.textos}>{item.nome}</Typography>
                    </Grid>
                </Grid>
                <Grid xs={12} sm={4} md={6} container justifyContent="center" spacing={1}>
                    <Grid
                        item
                        xs={12}
                        sm={6}
                        md={4}
                        container
                        direction="column"
                        justifyContent="center"
                    >
                        {item?.tipo === "O" ? (
                            <>
                                <Typography>Passo de Montagem</Typography>
                                <Typography className={classes.textos}>{item?.grupo}</Typography>
                            </>
                        ) : null}
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        sm={6}
                        md={4}
                        container
                        direction="column"
                        justifyContent="center"
                    >
                        <TextField
                            type="number"
                            id="codigoPDV"
                            fullWidth
                            variant="outlined"
                            label="CódigoPDV"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            placeholder="CódigoPDV"
                            defaultValue={item.codigoPDV}
                            onChange={handleChangeCodigoPDV}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                        <TextField
                            type="number"
                            id={`inputValorMassa${item.id}`}
                            fullWidth
                            variant="outlined"
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">R$</InputAdornment>
                                ),
                            }}
                            inputProps={{
                                style: { textAlign: "right" },
                                placeholder: "0.00",
                            }}
                            label="Preço"
                            value={item.valor}
                            onChange={e => handleInputChange(item.id, e.target.value)}
                        />
                    </Grid>
                </Grid>
                <Grid container xs={12} sm={4} md={2} justifyContent="center">
                    <DisponibilidadeSwitch
                        produto={item.grupo ? false : true}
                        disponivel={item?.ativo}
                        handleChangeDisponibilidadeProduto={handleChangeDisponibilidadeProduto}
                        handleChangeDisponibilidadeOpcao={handleChangeDisponibilidadeOpcao}
                    />
                </Grid>
            </Grid>
        </ListItem>
    )
}
