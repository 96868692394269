import { Button, Grid, makeStyles, TextField } from "@material-ui/core"
import { AddAPhoto } from "@material-ui/icons"
import { useSnackbar } from "notistack"
import { useContext, useRef, useState } from "react"
import LoadingContext from "../../../contexts/loadingContext"
import { inativarOpcao } from "../../../services/cardapio"
import DisponibilidadeSwitch from "./DisponibilidadeSwitch"

const useStyles = makeStyles(() => ({
    container: {
        padding: "1rem 5px 1rem 0",
    },
    containerImg: {
        margin: "auto",
        width: "100px",
        height: "100px",
        backgroundColor: "#eee",
        border: "2px dashed #ccc",
        cursor: "pointer",
    },
    addAPhoto: {
        margin: "auto",
        display: "block",
        width: "30%",
        height: "30%",
        color: "rgba(0,0,0,0.5)",
    },
    input: {
        display: "none",
    },
    conteudo: {
        margin: "auto",
    },
    botaoExcluir: {
        backgroundColor: "red",
        color: "white",
        "&:hover": {
            backgroundColor: "#FF4D4D",
        },
    },
    // dragIndicator: {
    //     cursor: "move",
    // },
}))

export default function ItemAdicional({
    produto,
    passo,
    item,
    setDraggingAdicionais,
    index,
    handleExcluirOpcao,
    handleIdsEditar,
    handleCadastrarImagem,
}) {
    const { setLoading } = useContext(LoadingContext)
    const { enqueueSnackbar } = useSnackbar()

    const alertStart = (msg, tipo) => {
        enqueueSnackbar(msg, { variant: tipo })
    }

    const fileInputRef = useRef(null)
    const handleDivClick = () => {
        fileInputRef.current.click()
    }
    const handleFileChange = event => {
        const file = event.target.files[0]

        const nomeArquivo = file.name
        const idProduto = produto.id
        const idOpcao = item.id
        const idPasso = passo.id

        if (file) {
            handleCadastrarImagem(nomeArquivo, idProduto, idPasso, idOpcao, file)
        }
    }

    const classes = useStyles()

    // const handleDragStart = index => {
    //     setDraggingAdicionais(index)
    // }
    // const handleDragEnd = () => {
    //     setDraggingAdicionais(null)
    // }

    const [disponivel, setDisponivel] = useState(item?.ativo)
    const handleChangeDisponibilidadeOpcao = async () => {
        setLoading(true)
        const response = await inativarOpcao(item.id, disponivel)
        if (response.retornoErro) {
            console.error(response)
            alertStart(response.mensagem, "error")
        } else {
            setDisponivel(prev => !prev)
            console.warn("inativarProduto => ", response)
            alertStart("Disponibilidade da opção alterada com sucesso", "success")
        }
        setLoading(false)
    }

    return (
        <Grid
            container
            alignItems="center"
            // draggable
            // onDragStart={e => handleDragStart(index)}
            // onDragEnd={handleDragEnd}
        >
            {/* <IconButton>
                <DragIndicator className={classes.dragIndicator} />
            </IconButton> */}
            <Grid
                item
                container
                justifyContent="center"
                alignItems="center"
                className={classes.containerImg}
                onClick={handleDivClick}
            >
                <Grid>
                    {item.urlImagem ? (
                        <img src={item.urlImagem} height={"90px"} />
                    ) : (
                        <Grid>
                            <AddAPhoto className={classes.addAPhoto} />
                        </Grid>
                    )}
                </Grid>
                <input
                    type="file"
                    ref={fileInputRef}
                    onChange={handleFileChange}
                    className={classes.input}
                    accept="image/*"
                />
            </Grid>
            <Grid item container spacing={1} xs={12} sm={12} md={10} className={classes.conteudo}>
                <Grid item container xs={12} sm={12} md={1}>
                    <TextField
                        fullWidth
                        type="number"
                        variant="outlined"
                        label="Exibiçao"
                        size="small"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        defaultValue={item.ordemExib}
                        onChange={e => {
                            handleIdsEditar(produto.id)
                            item.ordemExib = e.target?.value
                        }}
                    />
                </Grid>
                <Grid item container xs={12} sm={12} md={4}>
                    <TextField
                        fullWidth
                        type="text"
                        variant="outlined"
                        label="Nome"
                        size="small"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        defaultValue={item.nome}
                        onChange={e => {
                            handleIdsEditar(produto.id)
                            item.nome = e.target?.value
                        }}
                    />
                </Grid>
                <Grid item container xs={12} sm={12} md={2}>
                    <TextField
                        type="number"
                        fullWidth
                        size="small"
                        variant="outlined"
                        label="Preço"
                        // InputProps={{
                        //     startAdornment: <InputAdornment position="start">R$</InputAdornment>,
                        //     inputComponent: ReaisMask,
                        // }}
                        // inputProps={{
                        //     style: {
                        //         textAlign: "right",
                        //     },
                        // }}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        defaultValue={item.valor}
                        onChange={e => {
                            handleIdsEditar(produto.id)
                            item.valor = Number(e.target?.value)
                        }}
                    />
                </Grid>
                <Grid item container xs={12} sm={12} md={2}>
                    <TextField
                        fullWidth
                        type="number"
                        variant="outlined"
                        label="Código PDV"
                        size="small"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        defaultValue={item.codigo}
                        onChange={e => {
                            handleIdsEditar(produto.id)
                            item.codigo = e.target?.value
                        }}
                    />
                </Grid>

                <Grid
                    item
                    container
                    xs={12}
                    sm={12}
                    md={3}
                    alignItems="center"
                    justifyContent="center"
                >
                    <DisponibilidadeSwitch
                        disponivel={disponivel}
                        handleChangeDisponibilidadeOpcao={handleChangeDisponibilidadeOpcao}
                    />
                </Grid>
                <Grid item container xs={12} sm={12} md={11}>
                    <TextField
                        multiline
                        fullWidth
                        type="text"
                        variant="outlined"
                        label="Descrição"
                        size="small"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        defaultValue={item.descricao}
                        onChange={e => {
                            handleIdsEditar(produto.id)
                            item.descricao = e.target?.value
                        }}
                    />
                </Grid>
                <Grid item container xs={12} sm={12} md={1}>
                    <Button
                        className={classes.botaoExcluir}
                        onClick={() => {
                            handleExcluirOpcao(produto.id, passo.id, item.id, index)
                        }}
                    >
                        Excluir
                    </Button>
                </Grid>
            </Grid>
        </Grid>
    )
}
