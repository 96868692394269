import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    TextField,
} from "@material-ui/core"
import { useEffect } from "react"

const DialogConfiguracoes = ({
    todasMesas,
    parametros,
    configuracoes,
    classes,
    printer,
    mesa,
    openConfiguracoes,
    handleCloseConfiguracoes,
    numeroMesa,
    handleChangeNumeroMesa,
    tipoPainelImpressao,
    atualizarConfiguracoes,
    tamanhoFonteSelect,
    exibirTamanhoFonte,
    setMudancaConfiguracao,
    tipoPrinter,
    ListaImpressorasSelecionadas,
    impressorasQZ,
    mudarMesa,
    verificarSeTemQZ,
}) => {
    useEffect(() => {
        verificarSeTemQZ()
    }, [])

    return (
        <Dialog open={openConfiguracoes} onClose={handleCloseConfiguracoes}>
            <DialogTitle>Configurações</DialogTitle>
            <DialogContent>
                <FormControl style={{ width: "100%" }}>
                    <InputLabel variant="outlined">Mesa</InputLabel>
                    <Select
                        variant="outlined"
                        value={numeroMesa}
                        label="Mesa"
                        onChange={handleChangeNumeroMesa}
                    >
                        {todasMesas.map((_, index) =>
                            index === 0 ? null : (
                                <MenuItem key={index} value={index}>
                                    {index}
                                </MenuItem>
                            )
                        )}
                    </Select>
                </FormControl>
                <Divider />
                <Grid className={classes.parametrosDeImpressao} container spacing={1}>
                    <Grid item xs={12} md={6} lg={6}>
                        <div style={{ flex: 1 }}>
                            <TextField
                                disabled
                                defaultValue={parametros.viasImpressao}
                                fullWidth
                                label="Quant. de Vias"
                                variant="outlined"
                            />
                        </div>
                        {tipoPainelImpressao === "navegador" ? (
                            <div style={{ flex: 1 }}>
                                <FormControl
                                    variant="outlined"
                                    className={classes.formControl}
                                    fullWidth
                                >
                                    <InputLabel>Tamanho Impr.</InputLabel>
                                    <Select
                                        fullWidth
                                        variant="outlined"
                                        value={configuracoes.printer.tamanhoFonteNavegador}
                                        defaultValue={"normal"}
                                        onChange={e => {
                                            const tmp = JSON.parse(JSON.stringify(configuracoes))
                                            tmp.printer.tamanhoFonteNavegador = e.target.value
                                            atualizarConfiguracoes(tmp)
                                        }}
                                        label="Tamanho Impr."
                                    >
                                        <MenuItem value="normal">Normal</MenuItem>
                                        <MenuItem value="grande">Grande</MenuItem>
                                    </Select>
                                </FormControl>
                            </div>
                        ) : null}
                    </Grid>

                    <Grid item xs={12} md={6} lg={6}>
                        <>
                            <FormControl
                                variant="outlined"
                                className={classes.formControl}
                                style={{ width: "100%" }}
                            >
                                <InputLabel id="tipopainel">Painel com Impressão</InputLabel>
                                <Select
                                    fullWidth
                                    value={tipoPainelImpressao}
                                    disabled
                                    label="Painel com Impressão"
                                >
                                    <MenuItem value="navegador">Navegador Nativo</MenuItem>
                                    <MenuItem value={"plugin"}>Plugin QZ</MenuItem>
                                    <MenuItem value={"software"}>Software EXE</MenuItem>
                                </Select>
                            </FormControl>
                        </>
                    </Grid>

                    <Grid item xs={12} md={6} lg={6}>
                        <>
                            <FormControl
                                variant="outlined"
                                className={classes.formControl}
                                style={{ width: "100%" }}
                            >
                                <InputLabel id="impressoras">Tamanho da Fonte</InputLabel>
                                <Select
                                    fullWidth
                                    value={tamanhoFonteSelect}
                                    onChange={e => {
                                        const tmp = JSON.parse(JSON.stringify(configuracoes))
                                        tmp.printer.tamanhoFonteSelect = e.target.value
                                        atualizarConfiguracoes(tmp)
                                    }}
                                    label="Tamanho da Fonte"
                                >
                                    <MenuItem value="pequena">
                                        <em>Pequena</em>
                                    </MenuItem>

                                    <MenuItem value="media">
                                        <em>Media</em>
                                    </MenuItem>

                                    <MenuItem value="grande">
                                        <em>Grande</em>
                                    </MenuItem>
                                </Select>
                            </FormControl>
                        </>
                    </Grid>

                    {tipoPainelImpressao === "plugin" &&
                        tipoPainelImpressao !== "software" &&
                        exibirTamanhoFonte && (
                            <Grid item xs={12} md={6} lg={6}>
                                <TextField
                                    id="tamanho-font"
                                    value={printer.tamanhoFonteImpressao}
                                    onChange={e => {
                                        // if(e.target.value >= 10 && e.target.value <= 30){
                                        var valor = e.target.value
                                        // document.getElementsByTagName('HTML')[0].style.fontSize = `${valor}%`

                                        const tmp = JSON.parse(JSON.stringify(configuracoes))
                                        tmp.printer.tamanhoFonteImpressao = valor
                                        atualizarConfiguracoes(tmp)
                                        setMudancaConfiguracao(true)
                                        // }
                                    }}
                                    fullWidth
                                    placeholder={"14"}
                                    type={"number"}
                                    label="Tamanho Fonte Impressão"
                                    variant="outlined"
                                />
                            </Grid>
                        )}

                    <Grid item xs={12} md={6} lg={6}>
                        {tipoPainelImpressao === "plugin" ? (
                            <>
                                <FormControl
                                    variant="outlined"
                                    className={classes.formControl}
                                    style={{ width: "100%" }}
                                >
                                    <InputLabel id="impressoras">
                                        Tipo Printer Cliente Fiel
                                    </InputLabel>
                                    <Select
                                        fullWidth
                                        labelId="tipoImpressao"
                                        id="tipoImpressao"
                                        value={tipoPrinter}
                                        // value={}
                                        onChange={e => {
                                            const tmp = JSON.parse(JSON.stringify(configuracoes))
                                            tmp.printer.tipoImpressao = e.target.value
                                            atualizarConfiguracoes(tmp)
                                        }}
                                        label="Tipo Printer Cliente Fiel"
                                    >
                                        <MenuItem value="code">
                                            <em>Tipo CODE</em>
                                        </MenuItem>

                                        <MenuItem value="html">
                                            <em>Tipo HTML</em>
                                        </MenuItem>
                                    </Select>
                                </FormControl>
                            </>
                        ) : null}
                    </Grid>

                    <ListaImpressorasSelecionadas
                        tipoPainelImpressao={tipoPainelImpressao}
                        impressorasQZ={impressorasQZ}
                    />
                </Grid>
                {!impressorasQZ.length > 0 && (
                    <div
                        style={{
                            marginTop: "1em",
                            width: "100%",
                            display: "flex",
                            gap: "1em",
                        }}
                    >
                        <Button
                            className={classes.botoesImpressoras}
                            onClick={e => {
                                verificarSeTemQZ()
                            }}
                        >
                            Identificar Printer
                        </Button>
                        <Button
                            className={classes.botoesImpressoras}
                            onClick={e => {
                                window.location.href =
                                    "https://clientefielsp.s3-sa-east-1.amazonaws.com/Cliente+Fiel+Printer.zip"
                            }}
                        >
                            Download Printer
                        </Button>
                    </div>
                )}
            </DialogContent>
            <DialogActions className={classes.actionsConfiguracoes}>
                <Button onClick={handleCloseConfiguracoes}>Fechar</Button>
                <Button
                    onClick={() => {
                        if (mesa.numeroMesa !== numeroMesa) {
                            mudarMesa(numeroMesa)
                        }
                        handleCloseConfiguracoes()
                    }}
                >
                    Salvar
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default DialogConfiguracoes
